import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import InstagramIcon from "../images/instagram.svg"
import GithubIcon from "../images/github.svg"
import TwitterIcon from "../images/twitter.svg"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `full stack developer`,
        `london`,
        `react`,
        `flutter`,
        `node.js`,
        `golang`,
      ]}
    />
    <h1>Fares Marek Basmadji</h1>
    <ul>
      <li>
        <span role="img" aria-label="laptop emoji">
          💻
        </span>
        &nbsp;&nbsp;software engineer{" "}
        <a href="https://www.theguardian.com">@guardian</a>
      </li>
      <li>
        <span role="img" aria-label="piano emoji">
          🎹
        </span>
        &nbsp;&nbsp;former concert{" "}
        <a href="https://www.youtube.com/user/fmbasmadji">pianist</a>
      </li>
      <li>
        <span role="img" aria-label="tada emoji">
          🎉
        </span>
        &nbsp;&nbsp;enjoys react, swift, elm and golang
      </li>
      <li>
        <span role="img" aria-label="love letter emoji">
          💌
        </span>
        &nbsp;&nbsp;love letters or other{" "}
        <a href="mailto:fm&#64;iam.fm">inquiries</a>
      </li>
      <li>
        <span role="img" aria-label="Syrian, Polish and GB flag">
          🇸🇾🇵🇱🇬🇧
        </span>
        &nbsp;&nbsp;Syrian Polish mix living in the UK
      </li>
    </ul>
    <ul className="links">
      <li>
        <a href="https://github.com/faresite">
          <GithubIcon className="icon" />
        </a>
      </li>
      <li>
        <a href="https://instagram.com/faresite">
          <InstagramIcon className="icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/faresite">
          <TwitterIcon className="icon" />
        </a>
      </li>
    </ul>
  </Layout>
)

export default IndexPage
