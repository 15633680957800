/** @jsx jsx */
import PropTypes from "prop-types"
import { Global, css, jsx } from "@emotion/core";
import { Fragment } from "react";
import "../fonts/fonts.css";
import "./layout.css"

const Layout = ({ children }) =>
  <Fragment>
    <Global
      styles={css`
        html {
          padding: 3rem 2rem;
          height: 100%;
          background-image: linear-gradient(-20deg, #335 0%, #113 100%);
        }
        * {
          color: hotpink;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
      `}
    />
    <main>{children}</main>
  </Fragment>;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
